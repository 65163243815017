html {
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  box-sizing: border-box;
  color: #333;
  height: 100vh;
  margin: 0;
  padding: 0.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.label-pair {
  align-items: center;
  display: flex;
  gap: 0.35em;
}

.p-tabpanels {
  height: 100%;
}

.wide > .p-autocomplete {
  width: 100%;

  > input {
    width: 100%;
  }
}

.info-icon {
  cursor: pointer;
  color: dodgerblue;
  text-align: center;

  &.disabled {
    color: #CCC;
    cursor: default;
    pointer-events: none;
  }
}

.note-wrapper {
  align-items: center;
  background-color: transparent;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  .note-display {
    background-color: #FFD;
    border: solid 1px black;
    box-sizing: border-box;
    max-height: min(800px, 80vh);
    max-width: min(600px, 80vw);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1em 1em 1em 4em;
    position: relative;

    .closer {
      cursor: pointer;
      padding: 0.5em;
      pointer-events: all;
      position: absolute;
      right: -2.5em;
      top: -1em;
    }

    .top-line {
      background-color: #FFD;
      box-shadow: 0 0 4px 4px #FFD;
      position: sticky;
      top: 0;

      .date-time {
        color: #06F;
      }

      .release {
        background-color: #FFD;
        box-shadow: 0 0 4px 4px #FFD;
      }

      .release-version {
        font-weight: bold;
      }

      .top-mask {
        background-color: #FFD;
        height: 1em;
        left: -3em;
        position: absolute;
        right: 0;
        top: -1em;
      }
    }

    > div {
      overflow: visible;
      padding-left: 0;
      text-align: start;
      text-indent: -3em;
      white-space: pre-line;
    }
  }
}

.tbw-dse-closer svg {
  top: -0.5em;
}

.tze-disabled {
  opacity: 0.25;
}

.clock-add > .p-button-icon-only {
  margin-top: 1.5px;
}

code {
  font-family: Lucida Console, monospace;
  font-size: 0.8em;
}
